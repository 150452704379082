import { createRouter, createWebHistory, type Router } from 'vue-router'
import { getRoutes } from './routing'

let router: Router

const __router__ = getRoutes({
  views: import.meta.glob('../views/**/*.vue'),
  viewConfig: import.meta.glob('../views/**/*.ts')
}).then((routes) => {
  const routerTmp = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
  })

  router = routerTmp
  return routerTmp
})

export { router, __router__ }
