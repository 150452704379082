import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { router, __router__ } from "./router";
import axios, { type AxiosResponse } from "axios";

import { AuthorizationTokenKey } from "@/constant/user";
import "@/styles/index.css";
import "@/styles/scrollbar.scss";

axios.defaults.baseURL = "/api";

axios.interceptors.request.use((config) => {
  if (config.headers) {
    const token = sessionStorage.getItem(AuthorizationTokenKey);
    if (token !== null) {
      config.headers[AuthorizationTokenKey] = token;
    }
  }

  if (!config.paramsSerializer) {
    config.paramsSerializer = {};
  }
  config.paramsSerializer = { ...config.paramsSerializer, indexes: null };

  config.withCredentials = true;

  return config;
});

const handleResponse = (response: AxiosResponse) => {
  const token = response.headers[AuthorizationTokenKey];
  if (typeof token === "string" && token.length > 0) {
    sessionStorage.setItem(AuthorizationTokenKey, token);
  }
  if (response.status === 401) {
    if (window.location.pathname.indexOf("login") >= 0) {
      return Promise.reject(response);
    }
    const query: any = {
      redirectUrl: window.location
    };
    router.replace({
      name: "login",
      query: query
    });
  }
  return null;
};

axios.interceptors.response.use(
  (response) => {
    const result = handleResponse(response);
    if (result !== null) {
      return result;
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response) {
      const result = handleResponse(error.response);
      if (result !== null) {
        return result;
      }
    }
    return Promise.reject(error);
  }
);

const app = createApp(App);

app.use(createPinia());

__router__.then(() => {
  app.use(router);
  app.mount("#app");
});
